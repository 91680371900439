@import 'scss/variables';

body {
  .MobHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 5px 15px;
    width: 100%;
    height: 60px;
    background-color: $color-white;
    z-index: 99001;

    &.Transparent {
      position: absolute;
      top: 0;
      background-color: transparent;
    }

    @media (min-width: $md) {
      display: none;
    }
  }
}
