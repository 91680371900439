@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes close {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes sticky {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes clip-text {
  from {
    clip-path: inset(0 100% 0 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes text-revealer {
  0%,
  50% {
    transform-origin: 0 50%;
  }

  60%,
  100% {
    transform-origin: 100% 50%;
  }

  60% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes move-slideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}
