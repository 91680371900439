@import 'scss/variables';

body {
  .Footer {
    background-color: $color-black2;

    .Container {
      padding-top: 64px;
      padding-bottom: 80px;

      .Form {
        .Title {
          margin-bottom: 40px;
          text-align: center;
          color: $color-white;
        }

        .InputBlock {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 24px;

          margin: 0 auto;
          max-width: 500px;

          :global(.mu-container) {
            width: 100%;

            :global(.mu-input-container) {
              :global(.mu-input) {
                padding-left: 0px;
                color: $color-white;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid $color-white;
                border-radius: 1px;
              }

              &::placeholder {
                color: rgba($color-white, 0.8);
              }
            }
          }

          :global(.mu-outline-btn) {
            width: 100%;

            @media (min-width: $md) {
              width: auto;
            }
          }

          @media (min-width: $md) {
            flex-direction: row;
          }
        }
      }

      .Links {
        display: grid;
        grid-template-columns: 1fr;
        padding-top: 64px;
        padding-bottom: 64px;
        border-bottom: 1px solid rgba($color-white, 0.25);

        .Social {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;
          margin-bottom: 40px;

          > a {
            font-size: 24px;
            color: $color-white;
          }

          @media (min-width: $md) {
            margin-bottom: 0;
          }
        }

        .Menu {
          > a {
            display: block;
            margin-bottom: 12px;

            &:hover {
              text-decoration: underline;
              text-decoration-color: $color-white;
            }

            :global(.mu-p3) {
              color: $color-white;
            }
          }
        }

        .MenuList {
          display: flex;
          flex-direction: row;
          gap: 20px;

          .Menu {
            .Title {
              color: $color-white;
              padding-bottom: 2px;
              text-transform: uppercase;
              border-bottom: 2px solid $color-red;
              margin-bottom: 10px;
            }
          }
          @media (min-width: $md) {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        @media (min-width: $md) {
          grid-template-columns: 200px 1fr;
          padding-top: 80px;
          padding-bottom: 80px;
        }
      }
      .CustomLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        padding-top: 44px;
        padding-bottom: 24px;

        :global(.mu-p2) {
          color: $color-white;
          margin-bottom: 10px;
        }

        .List {
          margin-top: 10px;
          > a {
            display: block;
            margin-bottom: 5px;

            &:hover {
              text-decoration: underline;
              text-decoration-color: $color-white;
            }

            :global(.mu-p3) {
              color: $color-white;
            }
          }

          @media (min-width: $md) {
            margin-top: 0px;
          }
        }

        @media (min-width: $md) {
          grid-template-columns: repeat(5, 1fr);
          gap: 40px;
          padding-top: 60px;
        }
      }

      .Text {
        padding-top: 64px;

        &.Landing {
          padding-top: 0px;
        }

        :global(.mu-p4) {
          margin-bottom: 24px;
          color: rgba($color-white, 0.5);

          &:last-child {
            margin-bottom: 0;
          }
        }

        @media (min-width: $md) {
          padding-top: 80px;
        }
      }

      .AppBtns {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14px;
        margin-top: 28px;

        > a {
          display: inline-block;
        }

        @media (min-width: $md) {
          margin-top: 0;
        }
      }

      @media (min-width: $md) {
        padding-top: 80px;
      }
    }
  }
}
