@import 'scss/variables';

body {
  .Header {
    display: grid;
    column-gap: 8px;
    grid-template-columns: auto 3fr 1fr auto;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    padding-left: 5px;
    padding-right: 5px;
    height: 60px;
    width: 100%;
    background-color: $color-black;
    transition: top 0.5s;
    z-index: 99999;

    :global(.customInput) {
      > [role='combobox'] {
        border-radius: 40px;

        &:after {
          border-color: $color-black;
        }

        > input {
          border-radius: 40px;
        }

        > *:nth-child(2) {
          left: auto;
          right: 2px;
          background: $color-red5;
          color: #fff;
          border-radius: 50%;
          z-index: 99999;
        }

        > *:nth-child(4) {
          padding-left: 16px;
        }
      }
    }

    &.Transparent {
      background-color: transparent;
      box-shadow: none;
    }

    &[data-hide='true'] {
      top: -70px;
    }

    .HeaderAuth {
      display: none;

      @media (min-width: $sm) {
        display: flex;
      }
    }

    .MobNav {
      .MobBtn {
        border: none;
        border-radius: 50%;
        color: $color-white;
        background-color: transparent;
        padding: 13px;

        &.Open {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }

    @media (min-width: $xs) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (min-width: $sm) {
      grid-template-columns: auto 300px 1fr auto;
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (min-width: '992px') {
      grid-template-columns: auto 300px 3fr auto;
      padding-left: 40px;
      padding-right: 40px;
      top: 0;
      column-gap: 40px;
      height: 70px;
    }
  }
}
