@import 'scss/variables';

body {
  .ExpertModal {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 9999990;
    opacity: 1;
  }

  .Modal {
    display: flex;
    flex-direction: column;
    top: 65px;
    left: 50%;
    min-width: 140px;
    width: 335px;
    overflow-y: auto;
    position: fixed;
    z-index: 9999990;
    transform: translate(-50%, 0px);
    padding: 16px 24px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 8px 32px rgb(0 0 0 / 15%);

    .NotFound {
      > p {
        color: $color-black4;
      }
    }

    .List {
      padding: 8px;
      padding-bottom: 0px;

      .ElementOfList {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0px;
        }

        .Title {
          color: $color-black4;
          margin-bottom: 16px;
        }

        .Link {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0px;
          }

          &.Learn {
            align-items: flex-start;
          }

          > img {
            width: 32px;
            height: 32px;
            border-radius: 10px;
          }
        }
      }
    }

    @media (min-width: $sm) {
      left: 37%;
    }

    @media (min-width: $md) {
      top: 75px;
      left: 365px;
    }
  }
}
