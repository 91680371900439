@import 'scss/variables';

body {
  .HeaderAuth {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .LoginBtn {
      margin-right: 20px;
      padding: 8px;
      font-family: $font-heading;
      font-size: 16px;
      font-weight: 800;
      line-height: 22px;
      white-space: nowrap;
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 12px;
    }

    .UserBtn {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .Menu {
      min-width: 140px;
    }
  }
}
