@import 'scss/variables';

body {
  .NotFound {
    padding: 30px 15px;
    text-align: center;
    line-height: normal;
    color: $color-black5;

    * {
      line-height: inherit;
    }
  }
}
