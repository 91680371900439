html,
body {
  font-family: $font-main;
  -webkit-text-size-adjust: none;

  a {
    text-decoration: none;
  }

  input,
  select {
    font-size: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

body {
  font-size: 16px;
  background-color: $color-white;
  overflow-x: hidden;

  #nprogress {
    position: relative;
    z-index: 9999990;
  }

  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
  }

  .color-green {
    color: $color-teal;
  }

  .color-red {
    color: $color-pink-red;
  }

  .color-light-red {
    color: $color-red5;
  }

  .rte {
    > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $color-red5;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ul,
    ol {
      padding-left: 20px;
    }
  }

  .iframe-block {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    text-align: center;

    > iframe {
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }
}
