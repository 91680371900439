@import 'scss/variables';

.Logo {
  max-width: 180px;
  max-height: 24px;

  > i {
    margin-right: 10px;
    color: $color-red5;
  }

  img {
    max-width: 180px;
    max-height: 24px;
    width: auto;
    height: 100%;
  }
}
