@import 'scss/variables';

body {
  .DiscoverModal {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 9999990;
    opacity: 1;
  }

  .Modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    top: 65px;
    left: 50%;
    max-height: calc(100% - 40px);
    width: 100%;
    max-width: 648px;
    overflow-y: auto;
    position: fixed;
    z-index: 9999990;
    transform: translate(-50%, 0px);
    padding: 24px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 8px 32px rgb(0 0 0 / 15%);

    .Header {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .Left {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .InvestmentCard {
          display: flex;
          padding: 8px;
          flex-direction: row;
          gap: 8px;

          .ImgContainer {
            width: 20px;
            height: 20px;
          }

          &:hover {
            background: $color-black10;
          }

          :global(.mu-avatar) {
            border-radius: 4px !important;
          }

          .Contant {
            display: flex;
            flex-direction: column;

            :global(.mu-p4) {
              color: $color-black4;
            }
          }
        }
      }

      .Right {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 288px;

        .ReadCard {
          > p {
            &:hover {
              color: $color-black;
            }

            color: $color-black4;
          }
        }
      }
    }

    .Bottom {
      display: flex;
      justify-content: flex-end;
      padding-top: 24px;
      border-top: 1px solid $color-black8;

      > a {
        width: 130px;
      }
    }

    @media (min-width: $md) {
      top: 75px;
      right: 70px;
      left: auto;
    }
  }
}
