@import 'scss/variables';

body {
  .HeaderMob {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $color-black;
    color: $color-white;
    top: 60px;
    bottom: auto;
    left: 0;
    right: 0;
    z-index: 99999999;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    gap: 10px;
    padding-left: 24px;
    padding-right: 24px;
    height: calc(100vh - 130px);
    overflow-y: auto;

    .MenuItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      max-width: 327px;
      gap: 8px;
      padding: 8px 0px;
      color: inherit;

      &:not(.ActiveDark).Active {
        color: $color-red5;

        &::before {
          background-color: $color-red5;
          transform: translateY(0);
        }
      }

      &.Active.ActiveDark {
        &:before {
          background-color: $color-black;
          transform: translateY(0);
        }
      }

      &.Active {
        .Label {
          transform: translateY(6px);

          @media (min-width: $sm) {
            transform: translateY(0);
          }
        }
      }

      .Icon {
        width: 40px;
        height: 40px;
      }

      .Label {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        white-space: nowrap;
        color: inherit;
        transition: transform $trans;
      }

      @media (min-width: $sm) {
        overflow: hidden;
      }
    }

    .UserData {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border-top: 1px solid rgba(232, 232, 235, 0.2);
      border-bottom: 1px solid rgba(232, 232, 235, 0.2);
      padding: 9px;
      padding-left: 2px;

      .BtnList {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 327px;
        color: $color-white;
        gap: 17px;
        padding: 15px 9px;
        padding-left: 6px;
        border: none;
        background-color: transparent;
        font-family: $font-main;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
      }
    }

    .DiscoverData {
      display: flex;
      flex-direction: column;
      gap: 17px;

      .Left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-bottom: 1px solid rgba(232, 232, 235, 0.2);
        padding-bottom: 17px;

        .Title {
          color: $color-black5;
        }

        .InvestmentCard {
          display: flex;
          flex-direction: row;
          gap: 8px;

          :global(.mu-avatar) {
            border-radius: 8px !important;
          }

          .Content {
            display: flex;
            flex-direction: column;

            .Name {
              color: $color-white;
            }

            :global(.mu-p4) {
              color: $color-black7;
            }
          }
        }
      }

      .Right {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .Title {
          color: $color-black5;
        }

        .ReadCard {
          > p {
            color: $color-white;
          }
        }
      }
    }

    .AskExpert {
      border-top: 1px solid rgba(232, 232, 235, 0.2);
      border-bottom: 1px solid rgba(232, 232, 235, 0.2);
      padding: 8px;
      padding-left: 6px;

      &.Logged {
        border-top: none;
        border-bottom: none;
      }

      .GoToArt {
        background-color: #79eabb;
        color: $color-white;
        border-radius: 20px;
        padding: 8px 16px;
        border: none;

        > p {
          color: #1b383d;
        }

        &:hover {
          background-color: $color-white;
          color: #79eabb;

          > p {
            color: #1b383d;
          }
        }
      }
    }

    .Auth {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;
      padding-top: 6px;

      .LoginBtn {
        display: flex;
        gap: 8px;
        padding: 8px 5px;
        font-family: $font-main;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
        background-color: transparent;
        color: $color-white;
        border: none;
      }
    }
  }
}
