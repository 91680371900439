@import 'scss/variables';

body {
  .HeaderPadding {
    @media (min-width: $md) {
      padding-top: 70px;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    top: 70px;
    left: 50%;
    max-height: calc(100% - 40px);
    min-width: 140px;
    width: 335px;
    overflow-y: auto;
    position: fixed;
    z-index: 99999999910;
    transform: translate(-50%, 0px);
    padding-top: 11px;

    .Trigla {
      position: absolute;
      width: 0;
      height: 0;
      top: 0px;
      right: 50px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 12px solid $color-white;
      z-index: 99999999999;

      @media (min-width: $sm) {
        right: 36px;
      }

      @media (min-width: $md) {
        right: 64px;
      }
    }

    .ModalCard {
      background-color: #fff;
      box-shadow: 0 8px 32px rgb(0 0 0 / 15%);
      border-radius: 20px;

      .CloseBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        height: 30px;
        justify-content: center;
        left: auto;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 30px;
      }

      .Body {
        padding: 8px;
        padding-right: 32px;

        .Content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          > p {
            span {
              color: $color-red5;
            }
          }
        }
      }
    }
    @media (min-width: $sm) {
      right: -120px;
      left: auto;
    }

    @media (min-width: $md) {
      top: 85px;
      right: 30px;
    }
  }

  .ToTopBtn {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 100px;
    right: 24px;
    width: 36px;
    height: 36px;
    font-size: 24px;
    color: $color-white;
    background-color: $color-black2;
    border: none;
    border-radius: 50%;
    z-index: 999999999;

    @media (min-width: $md) {
      display: flex;
    }
  }
}
