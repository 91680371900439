@import 'scss/variables';

body {
  .GlobalCta {
    position: fixed;
    width: 100%;
    max-width: 376px;
    height: 300px;
    padding: 0 20px;
    bottom: 98px;
    right: 50%;
    transform: translateX(50%) !important;

    .GlobalCtaContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      padding: 24px 31px;
      width: 100%;
      max-width: 336px;
      background-color: $color-white;
      box-shadow: $card-shadow;
      border-radius: 12px;
      z-index: 99999999900;

      .CloseBtn {
        position: absolute;
        right: 15px;
        top: 15px;
        background-color: transparent;
        border: none;
      }

      .HeaderImg {
        object-fit: cover;
        width: 174px;
        height: 124px;
        margin-bottom: 16px;
      }

      .Description {
        margin-bottom: 20px;
      }

      .SubmitButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-red5;
        background-color: transparent;
        border: none;

        > p {
          color: $color-red5;
          margin-right: 5px;
        }
      }
    }

    @media (min-width: $sm) {
      transform: none !important;
      right: 6px;
      bottom: 55px;
    }
  }
}
