@import 'scss/variables';

body {
  .HeaderMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;

    .MenuItem {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 5px;
      height: inherit;
      color: inherit;
      background-color: transparent;
      border: none;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 5px;
        background-color: transparent;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transform: translateY(100%);
        transition: transform $trans;
      }

      &:not(.ActiveDark).Active {
        color: $color-red5;

        &::before {
          background-color: $color-red5;
          transform: translateY(0);
        }
      }

      &.Active.ActiveDark {
        &:before {
          background-color: $color-black;
          transform: translateY(0);
        }
      }

      &.Active {
        .Icon {
          transform: translate(-50%, 0);
          opacity: 1;

          @media (min-width: $sm) {
            display: none;
          }
        }

        .Label {
          transform: translateY(6px);

          @media (min-width: $sm) {
            transform: translateY(0);
          }
        }
      }

      .Icon {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, -6px);
        width: 50px;
        height: 50px;
        opacity: 0;
        transition: opacity $trans-fast, transform $trans;
      }

      .Label {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        white-space: nowrap;
        color: inherit;
        transition: transform $trans;
      }

      @media (min-width: $sm) {
        overflow: hidden;
      }
    }

    .AskExperts {
      display: flex;
      gap: 9px;
      align-items: center;
      color: $color-white;
      background-color: transparent;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: none;

      &.Open {
        color: $color-red5;

        > p {
          color: $color-red5;
        }
      }

      > p {
        color: $color-white;
      }

      @media (min-width: $md) {
        padding: 5px 24px;
        border-left: 1px solid rgba(232, 232, 235, 0.2);
        border-right: 1px solid rgba(232, 232, 235, 0.2);
      }
    }

    .GoToArt {
      background-color: #79eabb;
      color: #1b383d;
      border-radius: 20px;
      padding: 8px 16px;
      border: none;

      > p {
        color: #1b383d;
      }

      &:hover {
        background-color: $color-white;
        color: #79eabb;

        > p {
          color: #1b383d;
        }
      }
    }

    .LoginBtn {
      display: block;
      padding: 8px 10px !important;
      font-size: 14px !important;
      line-height: 22px !important;

      @media (min-width: $sm) {
        display: none;
      }
    }

    :global(.expertModal) {
      top: 75px !important;
    }

    @media (min-width: $xs) {
      gap: 20px;
    }

    @media (min-width: $sm) {
      justify-content: flex-end;
    }

    @media (min-width: $md) {
      gap: 40px;
    }
  }
}
