@import 'scss/variables';

body {
  .UserAvatar {
    display: flex;
    gap: 12px;
    align-items: center;

    > p {
      color: $color-white;
    }
  }
}
